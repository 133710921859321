import React, { useMemo } from 'react'
import { Col, Row } from 'react-grid-system'
import { navigate, Link } from 'gatsby'

import { isEmptyObject } from '../../../../common/utils/functions'
import { isEmployeeRole } from '../../../../common/utils/roles'
import { useItemTotals, useProfile, useAPI } from '../../../../hooks'
import { NoResults, StatTile, Widget } from '../..'
import { StatusValue } from '../../../../types'
import { Paths } from '../../../../constants/structure'

import { ItemTotals } from '../../../../hooks/useItemTotals/useItemTotals.type'
import {
   COMPANY_TOTALS,
   EMPLOYEE_TOTALS,
   ITEM_TOTALS,
} from './totalsWidget.constants'

import * as styles from './totalsWidget.module.scss'
import { PATH } from '../../../../constants/global'
import { Type } from '../../../../hooks/useItemTypes/useItemTypes.type'

enum TypeValues {
   STANDARD = '1',
   QUANTITY = '2',
   KIT = '3',
   CONSUMABLE = '4',
}

const TotalsWidget = (): JSX.Element => {
   const request = useAPI()
   const { data, isLoading } = useItemTotals()
   const { profile } = useProfile()

   const displayNoTileIncludeTotalOnlyCompany: string[] = ['unavailable']
   const displayNoTileIncludeTotalOnlyEmployee: string[] = ['pending']

   function getItemTotalsAsSegments(counts: ItemTotals, types: string[]) {
      return Object.entries(counts)
         .filter(([key]) => types.includes(key))
         .map(([key, value]) => {
            const displayTile = isEmployeeUser
               ? !displayNoTileIncludeTotalOnlyEmployee.includes(key)
               : !displayNoTileIncludeTotalOnlyCompany.includes(key)
            return {
               color: '',
               label: key,
               num: value,
               displayTile: displayTile,
            }
         })
   }

   const handleButtonClick = async (
      status: StatusValue,
      itemTypes: TypeValues[]
   ) => {
      try {
         const { data: itemTypesList }: { data: Type[] } = await request.get(
            PATH.ITEM_TYPES.LIST
         )
         const options = {
            state: {
               status,
               itemTypes: itemTypesList
                  .filter((type) =>
                     itemTypes.includes(type.Value as TypeValues)
                  )
                  .map((type) => ({ label: type.Text, value: type.Value })),
            },
         }
         return navigate(Paths.ITEMS, options)
      } catch (error) {
         console.log(error)
      }
   }

   const isEmployeeUser = isEmployeeRole(profile.Roles || [])
   const segments = getItemTotalsAsSegments(
      data,
      isEmployeeUser ? EMPLOYEE_TOTALS : COMPANY_TOTALS
   )
   const showWidget = !isLoading && !isEmptyObject(data)
   const columnWidthOneLine =
      12 / segments.filter((segment) => segment.displayTile).length

   const total = useMemo(() => {
      return segments.reduce((total, segment) => total + segment.num, 0)
   }, [segments])

   const title = showWidget
      ? `${total} item${total !== 1 ? 's' : ''} in total`
      : 'Loading...'

   return (
      <Widget
         heading={title.includes('NaN') ? `Error fetching totals` : title}
         hideSpinner
         isLoading={!showWidget}
         className={styles.wrapper}
      >
         <Row gutterWidth={15} className={styles.row}>
            {!isLoading && !total && (
               <Col xs={12}>
                  <NoResults>
                     {isEmployeeUser ? (
                        <>
                           <div>Your company hasn't added any items yet.</div>
                           <div>
                              Please contact your company admin for more
                              details.
                           </div>
                        </>
                     ) : (
                        <>
                           <div>You haven't added any items yet.</div>
                           <div>
                              <Link to={Paths.ITEMS}>Add your first item</Link>
                           </div>
                        </>
                     )}
                  </NoResults>
               </Col>
            )}
            {!isLoading &&
               !!total &&
               segments.map((segment, index) => {
                  return (
                     segment.displayTile && (
                        <Col
                           xs={isEmployeeUser ? 12 : 6}
                           sm={isEmployeeUser ? columnWidthOneLine : 6}
                           lg={columnWidthOneLine}
                           key={segment.label}
                        >
                           <StatTile
                              color={ITEM_TOTALS[segment.label].color}
                              icon={ITEM_TOTALS[segment.label].icon}
                              isLoading={isLoading}
                              label={ITEM_TOTALS[segment.label].label}
                              num={segment.num}
                              onClick={() =>
                                 handleButtonClick(
                                    ITEM_TOTALS[segment.label].id,
                                    segment.label === 'consumableAvailable'
                                       ? [TypeValues.CONSUMABLE]
                                       : [
                                            TypeValues.STANDARD,
                                            TypeValues.QUANTITY,
                                            TypeValues.KIT,
                                         ]
                                 )
                              }
                           />
                        </Col>
                     )
                  )
               })}
         </Row>
      </Widget>
   )
}

export default TotalsWidget
