// extracted by mini-css-extract-plugin
export var button = "notificationsWidget-module--button--67a5e";
export var buttonActive = "notificationsWidget-module--button-active--bd4ab";
export var buttonText = "notificationsWidget-module--buttonText--e4617";
export var buttonTotal = "notificationsWidget-module--buttonTotal--5ed66";
export var buttons = "notificationsWidget-module--buttons--7ab0e";
export var noResults = "notificationsWidget-module--no-results--ac05b";
export var tabContent = "notificationsWidget-module--tab-content--b9667";
export var tabContentWrapper = "notificationsWidget-module--tab-content-wrapper--f0ef0";
export var totalLabel = "notificationsWidget-module--total-label--47fda";
export var totalText = "notificationsWidget-module--total-text--85c63";
export var widgetWrapper = "notificationsWidget-module--widget-wrapper--eaa7e";