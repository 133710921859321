import React from 'react';

import { LayoutLoggedIn } from '../components/views/layout';
import Dashboard from '../components/views/dashboard'

export default function() {
    return (
        <LayoutLoggedIn>
            <Dashboard />
        </LayoutLoggedIn>
    );
}