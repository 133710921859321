import {StatusValue} from '../../../../types';

export const COMPANY_TOTALS = ['available', 'consumableAvailable', 'loaned', 'pending', 'unavailable'];
export const EMPLOYEE_TOTALS = ['available', 'borrowed', 'consumableAvailable', 'pending'];

type CountConfigItem = {
    color: string;
    icon: string;
    label: string;
    id: StatusValue;
    restricted: string[];
}

type CountConfig = {
    [key: string]: CountConfigItem;
}

export const ITEM_TOTALS: CountConfig = {
    available: {
        color: '#6eb250',
        icon: 'warehouse',
        label: 'Available',
        id: StatusValue.Available,
        restricted: [],
    },
    borrowed: {
        color: '#1A4F90',
        icon: 'warehouse loaned',
        label: 'Borrowed',
        id: StatusValue.Borrowed,
        restricted: [],
    },
    consumableAvailable: {
        color: '#6eb250',
        icon: 'warehouse',
        label: 'Consumables',
        id: StatusValue.ConsumablesAvailable,
        restricted: ['ConsumablesEnabled'],
    },
    loaned: {
        color: '#b21a1f',
        icon: 'warehouse loaned',
        label: 'Loaned',
        id: StatusValue.Loaned,
        restricted: [],
    },
    pending: {
        color: '#e5622a',
        icon: 'warehouse',
        label: 'Pending',
        id: StatusValue.Pending,
        restricted: [],
    },
    unavailable: {
        color: '#96999b',
        icon: 'warehouse',
        label: 'Unavailable',
        id: StatusValue.Unavailable,
        restricted: [],
    }
}